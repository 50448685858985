<template>
        <div id="home" class="vanList">
                
                <div class="searchBox" @click="searchShow = true">
                    <button><i class="iconfont icon-sousuo"></i>搜索</button>
                    <!-- <p>搜索</p> -->
                </div>
                <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                :immediate-check="false"
                >
                
                <van-skeleton class="bannerLoading" :row="1" :loading="bannerLoading"/>
                <div v-if="!bannerLoading" class="banner">
                    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                        <van-swipe-item v-for="(item, index) in banners" :key="index">
                            <img :src="item.imgUrl"/>
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <div class="kingkong">
                    <div>
                        <p @click="todaysNewPopup = true">今日上新</p>
                    </div>
                    <!-- <div @click="jump('seckill')">
                        <p>商品秒杀</p>
                    </div> -->
                    <div @click="jump('type')">
                        <p>全部商品</p>
                    </div>
                   <!--  <div>
                        <p>优惠券</p>
                    </div> -->
                </div>

                <!-- <div class="seckill">
                    <div class="title">
                        <h4>商品秒杀</h4>
                        <button @click="jump('seckill')">更多</button>
                    </div>

                    <div class="list">
                        <van-skeleton :row="1" row-width="99%" :loading="seckillLoading" :animate="false" class="seckilLoading" v-for="item in 4" :key="item"/>
                        <div v-for="(item, index) in seckillData" :key="index" @click="jump('productdetails', item.productId)">
                            <img v-lazy="item.listImg"/>
                            <h4>{{item.productName}}</h4>
                            <p class="marking">￥{{item.marketPrice}}</p>
                            <p class="price">￥{{item.secKillPrice}}+{{item.secKillIntegral}}良豆</p>
                        </div>
                    </div>
                </div> -->

                <div class="product">
                    <div class="title">
                        <h4>推荐商品</h4>
                    </div>
                    <div class="list">
                        <van-skeleton :row="1" row-width="99%" :loading="productLoading" :animate="false" class="productLoading" v-for="item in 10" :key="item"/>
                        <div v-for="(item, index) in products" :key="index" @click="jump('productdetails', item.productId)">
                            <img v-lazy="item.listImg"/>
                            <h4>{{item.productName}}</h4>
                            <p>￥{{item.salePrice}}+{{item.integral}}良豆</p>
                        </div>
                    </div>
                </div>
                
                </van-list>

                <Search :searchShow="searchShow" :searchStaus.sync="searchShow"/>
                <todaysNew :todaysNewPopup="todaysNewPopup" :changePopup.sync="todaysNewPopup"/>
                
            <!-- </van-skeleton> -->
        </div>
</template>

<script>
import {getBanners, getflashsales, getproducts} from '../../api/home'
import {shopLogin, shopInfo} from '../../api/shop'
import {refreshtoken} from '../../api/login' 
import {getInfo} from '../../api/wxjsapi'
import Search from '../component/search'
import todaysNew from '../component/todaysNew'
export default {
    data () {
        return {
            bannerLoading: false,
            seckillLoading: true,
            productLoading: true,
            appKey: '',
            banners: [
                {imgUrl: require('@/image/01.jpg')},
                {imgUrl: require('@/image/02.jpg')},
                {imgUrl: require('@/image/03.jpg')},
            ], //banner
            searchShow: false,
            flashsaleData: { //秒杀产品入参
                appKey: '',
                index: 1,
                pageSize: 6
            },
            seckillData: [], //秒杀产品
            productData: { //商品列表入参
                appKey: '',
                shopLimit: 0,
                index: 1,
                pageSize: 20
            },
            products: [], //商品列表
            shopLoginData: {}, //店铺登录入参
            loading: false,
            finished: false,
            todaysNewPopup: false
        }
    },
    activated () {
        const scrollTop = this.$route.meta.scrollTop;
        const $content = document.querySelector('.vanList');
        if (scrollTop && $content) $content.scrollTop = scrollTop;
    },
    mounted () {
        let us = window.navigator.userAgent;
        if (us.indexOf('MicroMessenger') > -1) {  //微信浏览器
            this.getflashsales();
            this.getProduct();
            const {appKey, credits, timestamp, sign, uid, code, shoplimit} = this.$route.query;
            appKey && (this.appKey = appKey)
            shoplimit && (this.productData.shopLimit = parseInt(shoplimit));
            if (code) {
                // this.appKey = appKey;
                // this.getBanner();
                // this.getflashsales();
                this.getProduct();
                if (code && !localStorage.getItem('openid')) {
                    this.getOpenId(code);
                }
            } else {
                let local = encodeURIComponent(window.location.href);
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0812ff193b71ad32&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=1&#wechat_redirect`
            }
        } else { //非微信浏览器
            // this.getBanner();
            // this.getflashsales();
            const {shoplimit, appKey} = this.$route.query;
            appKey && (this.appKey = appKey)
            shoplimit && (this.productData.shopLimit = parseInt(shoplimit));
            this.getProduct();
        }
    },
    components: {
        Search,
        todaysNew
    },
    methods: {
        async getOpenId (code) {
            let res = await getInfo({code});
            if (res.code === 1) {
                localStorage.setItem('openid', res.data);
            }
        },

        /* 获取banner */
        async getBanner () {
            let res = await getBanners();
            // this.banners = res.data.list;
        },

        /* 获取秒杀产品 */
        async getflashsales () {
            const {flashsaleData, appKey} = this;
            this.flashsaleData.appKey = appKey;
            let res = await getflashsales(flashsaleData);
            const {code, data} = res;
            if (code == 1) {
                this.seckillData = data.list;
                // console.log(this.seckillData); 
                this.seckillLoading = false;
            };
        },

        /* 获取商品列表 */
        async getProduct () {
            const {productData, appKey} = this;
            this.productData.appKey = appKey;
            let res = await getproducts(productData);
            const {code, data, msg} = res;
            if (code === 1) {
                this.productData.index > 1 ?
                this.products.push(...data.list) :
                this.products = data.list;
                this.productLoading = false;
                this.loading = false;
                data.list.length < 20 && (this.finished = true);
            } else {
                this.$toast(msg);
            }
        },

        onLoad () {
            this.productData.index++;
            this.getProduct()
        },

        /* 页面跳转 */
        jump (name, productId) {
            if (name == 'type') {
                this.$emit('update:changeActive', 1);
            }
            const {$router, $route} = this;
            const {appKey, credits, timestamp, sign, uid, code, state, integral, shoplimit} = $route.query;
            $router.push({name, query:{appKey, credits, timestamp, sign, uid, productId, code, state, integral, shoplimit}});
        },

        /* 店铺登录 */
        async shopLogin () {
            const {shopLoginData, $toast} = this;
            let res = await shopLogin(shopLoginData);
            const {code, data, msg} = res;
            if (code === 1) {
                this.setToken(data);
                // this.getShopInfo();
            } else if (code === 0) {
                // $toast('token过期')
                let res = await refreshtoken({refreshToken: localStorage.getItem('refreshToken')});
                const {code, data, msg} = res;
                if (code === 1) {
                    this.setToken(data);
                }
            } else {
                $toast(msg);
            }
        },

        /* 存储token */
        setToken (data) {
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
        },

        /* 获取店铺信息 */
        async getShopInfo () {
            const {appKey} = this;
            let res = await shopInfo({appKey});
        },
    }
}
</script>

<style lang="less" src="./index.less">

</style>
