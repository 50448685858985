<template>
    <div id="serch">
        <van-popup v-model="searchPopup" class="searchPopup" :overlay="false">
            <div class="header">
                <form action="/">
                    <van-search v-model="productData.key" show-action placeholder="请输入关键词" @search="onSearch(1)" @cancel="onCancel"></van-search>
                </form>
            </div>
            <div :class="products.length > 0 ? 'content' : 'content content_bg'">
                <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                :immediate-check="false"
                 class="vanList"
                >
                    <div v-for="(item, index) in products" :key="index" class="card" @click="jump('productdetails', item.productId)">
                        <img :src="item.listImg"/>
                        <h4>{{item.productName}}</h4>
                        <p>￥{{item.salePrice}}+{{item.integral}}良豆</p>
                    </div>
                </van-list>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {getproducts} from '../../api/home'
export default {
    data () {
        return {
            searchPopup: false,
            productData: { //商品列表入参
                appKey: '',
                index: 1,
                pageSize: 20,
                key: '',
                shopLimit: 0
            },
            products: [],
            loading: false,
            finished: false,
        }
    },
    props: {
        searchShow: Boolean
    },
    mounted () {
            const {appKey, shoplimit} = this.$route.query;
            appKey && (this.productData.appKey = appKey);
            shoplimit && (this.productData.shopLimit = parseInt(shoplimit));
            this.searchPopup = this.searchShow;
    },
    watch: {
        searchShow (v, i) {
            console.log(v);
            if (v) {
                this.searchPopup = v;
               /*  this.products = [];
                this.productData.index = 1;
                this.productData.key = '';
                this.loading = false;
                this.finished =false; */
            }
        }
    },
    methods: {

        /* 搜索 */
        async onSearch (num) {
            document.querySelector('.vanList').scrollTop = 0;
            num && (this.productData.index = num);
            const {productData} = this;
            this.loading = false;
            this.finished =false;
            let res = await getproducts(productData);
            console.log(res);
            const {code, data, msg} = res;
            if (code == 1) {
                this.products = data.list;
                data.list.length == 0 && this.$toast('没有商品');
            } else {
                this.$toast(msg);
            }
        },

        /*  */
        async onLoad () {
            this.productData.index++;
            const {productData} = this;
            let res = await getproducts(productData);
            const {code, data, msg} = res;
            if (code == 1) {
                this.products.push(...data.list);
                this.productLoading = false;
                this.loading = false;
                data.list.length < 20 && (this.finished = true);
            }
        },

        /* 取消 */
        onCancel () {
            this.searchPopup = false;
            this.$emit('update:searchStaus', false);
            this.products = [];
            this.productData.index = 1;
            this.productData.key = '';
            this.loading = false;
            this.finished =false;

        },

        /* 页面跳转 */
        jump (name, productId) {
            setTimeout(() => {
                const {$router, $route} = this;
                const {appKey, credits, timestamp, sign, uid, code, state, integral} = $route.query;
                $router.push({name, query:{appKey, credits, timestamp, sign, uid, productId, code, state, integral}});
            })
        },
    }
}
</script>

<style lang="less" src="@/style/search.less">

</style>