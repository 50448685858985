<template>
    <van-popup id="todaysNew" v-model="todaysNewPopup" :overlay="false">
        <div class="header">
            <button @click="changePopup"><i class="iconfont icon-fanhui1"></i>今日上新</button>
        </div>
        <div class="list">
            <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
            class="listBox"
            >
                <div class="card" v-for="(item, index) in products" :key="index">
                    <img :src="item.listImg"/>
                    <h4>{{item.productName}}</h4>
                    <p>{{`￥${item.marketPrice}+${item.integral}良豆`}}</p>
                </div>
            </van-list>
        </div>
    </van-popup>
</template>

<script>
import {getproducts} from '../../api/home'
export default {
    data () {
        return {
            productData: { //商品列表入参
                appKey: '',
                index: 1,
                pageSize: 20
            },
            products: [],
            loading: false,
            finished: false
        }
    },
    props:{
        todaysNewPopup: Boolean,
    },
    mounted () {
                // this.getProduct();
    },
    watch: {
        todaysNewPopup (v, i) {
            if (v) {
                this.productData.index = 1;
                this.getProduct();
            }
        }
    },
    methods: {
        /* 弹窗popup操作 */
        changePopup () {
            this.$emit('update:changePopup', false);
        },

        /* 获取产品 */
        async getProduct () {
            const {productData, $route} = this;
            this.productData.appKey = $route.query.appKey;
            let res = await getproducts(productData);
            const {code, data, msg} = res;
            if (code === 1) {
                console.log(productData.index)
                productData.index === 1 ? 
                this.products = data.list :
                this.products.push(...data.list);
                this.loading = false;
                data.list.length < 20 && (this.finished = true);
            }
        },

        onLoad () {
            this.productData.index++;
            this.getProduct();
        }
    }
}
</script>

<style lang="less" src="@/style/component/todaysNew.less">

</style>